<template>
  <input :value="value" @input="updateValue($event)">
</template>

<script>


export default {
  name: "donateInput",
  props:['value'],
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    }
  }
}
</script>

<style scoped lang="scss">

input {
  color: var(--white, #FCFAF7);
  /* menu */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  text-transform: uppercase;
  background: none;
  border: 0.125rem solid var(--white, #FCFAF7);
  text-align: center;
  //width: 12.625rem;
  padding: 0.5rem 1.25rem;
}

input::placeholder {
  color: var(--white, #FCFAF7);
}

input:focus-visible {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Для Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

</style>