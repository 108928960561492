<template>
  <div class="header-gradient">
    <menu-top/>
    <donate class="margin-top"/>
  </div>

</template>

<script>
import MenuTop from "@/components/MenuTop.vue";
import Donate from "@/components/mainPage/donate.vue";

export default {
  name: "DonateView",
  components: {Donate, MenuTop}
}
</script>

<style scoped lang="scss">

.header-gradient {
  background: linear-gradient(to right, #EC408D, #FDB643);
  padding-top: 25px;

  .margin-top {
    margin-top: 0;
  }
}

</style>