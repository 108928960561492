<template>

  <div class="card">
    <h3>
      {{ title }}
    </h3>
    <img alt="" :src="image">
    <p ref="wallet" >
      {{ wallet }}
    </p>

    <div @click="click" class="copy">
      <span>{{ $t('copyAddress') }}</span>
    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "donateQrCode",
  props: ['title', 'image', 'wallet'],
  methods: {
    click(){
      this.$emit('click');
    },
    // copyText(){
    //   const textToCopy = this.$refs.wallet.innerText;
    //   const textarea = document.createElement('textarea');
    //   textarea.value = textToCopy;
    //   document.body.appendChild(textarea);
    //   textarea.select();
    //   document.execCommand('copy');
    //   document.body.removeChild(textarea);
    // }
  }
})
</script>


<style scoped lang="scss">

.card {
  padding: 0.75rem 0.875rem;
  border: 0.125rem solid var(--white, #FCFAF7);
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  h3 {
    color: var(--white, #FCFAF7);
    text-align: center;
    /* menu */
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    text-transform: uppercase;
  }

  img {
    margin: 0.625rem auto 0;
    object-fit: cover;
    max-width: 100%;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: auto;
    max-width: 100%;
    word-break: break-all;

    color: var(--white, #FCFAF7);
    text-align: center;

    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }

  .copy {
    margin-top: auto;
    padding-top: 0.75rem;
    color: var(--white, #FCFAF7);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
  }
}

</style>