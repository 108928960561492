import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTouch from 'vue-touch';
import VueI18n from 'vue-i18n'

import enMessages from './locales/en.json';
import ukMessages from './locales/uk.json';
import ruMessages from './locales/ru.json';

Vue.use(VueTouch);
Vue.use(VueI18n)

Vue.config.productionTip = false

const messages = {
    en: enMessages,
    uk: ukMessages,
    ru: ruMessages

};


const i18n = new VueI18n({
    locale: 'en',
    messages,
});

new Vue({
    i18n,
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
