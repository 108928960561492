<template>
  <div class="container">
    <h1>
      {{ $t('whatElseWeDo') }}
    </h1>
    <div class="wrapper">
      <div class="card">
        <h2 class="title">
          {{ $t('mediaWork') }}
        </h2>
        <p class="description">
          {{ $t('weDoMediamanagementWorkCreateAndEditContentDesignS') }}
        </p>
      </div>
      <div class="card">
        <h2 class="title">
          {{ $t('fundraising') }}
        </h2>
        <p class="description">
          {{ $t('weDoCrowdfundingHoldFundraisingEventsInOrderToGath') }}
        </p>
      </div>
      <div class="card">
        <h2 class="title">
          {{ $t('events') }}
        </h2>
        <p class="description">
          {{ $t('weOrganizeAndHostAwarenessraisingEventsTogetherWit') }}
        </p>
      </div>
    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "what-else"
})
</script>

<style scoped lang="scss">

.container {
  h1 {
    color: var(--pink, #EC408D);

    /* title */
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 120% */
    text-transform: uppercase;
    text-align: start;
    margin-left: 4.375rem;;
  }

  .wrapper {
    display: flex;
    gap: 3.417rem;
    margin-top: 2.5rem;

    .card {
      border: 4px solid var(--yellow, #FDB643);
      padding: 2rem;
      text-align: start;
      flex: 1;

      .title {
        color: var(--black, #1B1B1E);
        /* sub title */
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 128.571% */
        text-transform: uppercase;
      }

      .description {
        color: var(--black, #1B1B1E);

        /* text */
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 136.364% */
      }

    }
  }
}

@media (max-width: 978px) {
  .container {
    .wrapper {
      flex-direction: column;

      .card{
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}

</style>