<template>
  <div class="container">
    <h1>
      {{ $t('aboutUs2') }}
    </h1>
    <div class="text-container">
      <div class="block">
        <p>
          {{ $t('weAreQueerSvitUkrainianQueerWorld') }}<br><br>

          {{ $t('asABlackQueerrunIndependentTeamOfVolunteersQueerSv') }}<br><br>

          {{ $t('annamariaOneOfTheCofoundersAsWellAsTheCurrentOpera') }}
        </p>
      </div>
      <div class="block last">
        <p>
          {{ $t('queerSvitIsStillASmallerInitiativeWeCanContinueOur') }}<br><br>

          {{ $t('belowYouCanFindOutMoreAboutOurWork') }}
        </p>

      </div>
    </div>

  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "about-us"
})
</script>

<style scoped lang="scss">

.container {
  display: flex;
  flex-direction: column;
  margin-left: 4.375rem;
  margin-right: 4.375rem;
  margin-top: 5.625rem;
  padding-bottom: 5.125rem;


  h1 {
    color: var(--white, #FCFAF7);
    font-size: 90px;
    font-style: normal;
    font-weight: 600;
    line-height: 96px; /* 106.667% */
    letter-spacing: -2.7px;
    text-transform: uppercase;
    text-align: start;
  }

  .text-container {
    margin-top: 60px;
    display: flex;
    gap: 40px;

    .block {
      flex: 1;

      p {
        text-align: start;
        color: var(--white, #FCFAF7);
        /* text */
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }

}

@media (max-width: 978px) {
  .container {
    margin-left: 2rem;
    margin-right: 2rem;
    .block {
      &.last{
        display: none;
      }
    }
  }

}

</style>