<template>
  <div class="container">
    <div class="text-container">
      <h1>
        {{ $t('reportsOnOurActivities') }}
      </h1>
      <h2 @click="downloadReport">
        {{ $t('2022FullYearReports') }}
      </h2>
      <p>
        {{ $t('ifYouWantToFindOutMoreAboutOurWorkIn2022PleaseRead') }}
      </p>


    </div>

    <div class="image-container">
      <div class="box-animation">
        <Transition appear mode="in-out" name="fade">
          <img :key="currentImage" :src="images[currentImage]" alt="" class="image"/>
        </Transition>
        <img alt="" class="asterisk" src="@/assets/asterisk-2.png">
        <img alt="" class="left" src="@/assets/image-animation-left.png">
        <img alt="" class="right" src="@/assets/image-animation-right.png">
        <img alt="" class="branch" src="@/assets/animation-branch.png">
      </div>

    </div>

  </div>
</template>

<script>
import OutlineButton from "@/components/buttons/outlineButton.vue";
import reportPdf from '@/assets/report.pdf';

export default {
  name: "reportOnOurActivities",
  components: {OutlineButton},
  data() {
    return {
      reportUrl: reportPdf,
      images: [
        require('@/assets/we-are.png'),
        require('@/assets/image-slide-1.png'),
        require('@/assets/image-slide-2.png'),
      ],
      currentImage: 0,
    }
  },
  mounted() {
    this.startSlideshow();
  },
  methods: {
    downloadReport() {
      window.open(this.reportUrl, '_blank');
    },
    startSlideshow() {
      setInterval(() => {
        this.currentImage = (this.currentImage + 1) % this.images.length;
      }, 6000);
    },
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 2.5s ease;
}
.fade-enter, .fade-leave-to {
  transition: opacity 2.5s ease;
  opacity: 0;
  transition-delay: 2s;
}

.container {
  display: flex;
  margin-left: 4.375rem;
  //margin-right: 4.375rem;
  margin-top: 5.625rem;
  padding-bottom: 5.125rem;
  gap: 1rem;

  .text-container {
    flex: 1 0 50%;


    h1 {
      color: var(--yellow, #FDB643);
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px; /* 120% */
      text-transform: uppercase;
      text-align: start;

    }

    h2 {
      color: var(--yellow, #FDB643);
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px; /* 152.941% */
      letter-spacing: -1.02px;
      text-decoration-line: underline;
      text-transform: uppercase;
      text-align: start;
      margin-top: 1.875rem;
      cursor: pointer;
    }

    p {
      color: #000;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 136.364% */
      text-align: start;
      margin-top: 0.75rem;
    }

  }

  .image-container {
    flex: 1 0 50%;
    position: relative;
    display: flex;

    .box-animation {
      position: relative;
      width: 425px;
      margin-left: auto;

      img {
        position: absolute;
        top: 0;
        right: 0;
        //transform: translate(-50%, 50%);

        &.image {
          z-index: 8;
        }

        &.asterisk {
          left: -10%;
          top: 90%;
          z-index: 9;
          width: 90px;
          height: 90px; // Установите желаемую высоту картинки
          animation: spin-asterisk 6s linear infinite; // 2 секунды (1 секунда вправо и 1 секунда влево), и анимация бесконечная
        }

        @keyframes spin-asterisk {
          10%, 90% {
            transform: rotate(0deg);
            //animation-timing-function: ease-in;

          }
          40%, 60% {
            transform: rotate(40deg);
            //animation-timing-function: ease-in;
          }
          90% {
            transform: rotate(0deg);
            animation-timing-function: ease-in;
          }
        }

        &.left {
          left: -25%;
          top: 55%;
          z-index: 5;
          //width: 90px;
          //height: 90px; // Установите желаемую высоту картинки
          animation: spin-left 6s linear infinite;
        }

        @keyframes spin-left {
          10%, 90% {
            transform: rotate(0deg);
            //animation-timing-function: ease-in;
          }
          40%, 60% {
            transform: rotate(10deg);
            //animation-timing-function: ease-in;
          }
          90% {
            transform: rotate(0deg);
            //animation-timing-function: ease-in;
          }
        }

        &.right {
          right: -22%;
          top: 55%;
          z-index: 5;
          //width: 90px;
          //height: 90px; // Установите желаемую высоту картинки
          animation: spin-left 6s linear infinite;
        }

        &.branch {
          left: -28%;
          top: -8%;
          z-index: 5;
          //width: 90px;
          //height: 90px; // Установите желаемую высоту картинки
          animation: spin-branch 6s linear infinite;
        }

        @keyframes spin-branch {
          10%, 90% {
            transform: rotate(0deg);
            //animation-timing-function: ease-in;
          }
          40%, 60% {
            transform: rotate(-10deg);
            //animation-timing-function: ease-in;
          }
          90% {
            transform: rotate(0deg);
            //animation-timing-function: ease-in;
          }
        }
      }
    }


    @media (max-width: 1440px) {
      /* CSS styles for viewport width less than 1440px */
      img {
        scale: 0.8;
        //width: 60%;
        height: auto;
      }
    }
  }

  @media (max-width: 978px) {
    .image-container {
      display: none;
    }
  }
}


@media (max-width: 1440px) {
  .container{
    margin-left: 0;
  }
}

@media (max-width: 1500px) {

  .container {
    .image-container {
      .box-animation {
        img {
          &.asterisk {
            left: 0;
          }
          &.branch {
            left: -15%;
            top: -4%;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {

  .container {
    .image-container {
      scale: 0.8;
    }
  }


}
@media (max-width: 1050px) {

  .container {
    .image-container {
      .box-animation {
        img {
          &.asterisk {
            left: 0;
            top: 85%;
          }
          &.branch {
            left: -15%;
            top: 15%;
          }
        }
      }
    }
  }


}

</style>