<template>

  <button class="outline" :class="{'dark': dark}">{{text}}</button>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "outlineButton",
  props: ['text', 'dark']
})
</script>

<style scoped lang="scss">
  .outline{
    border: 3px solid var(--white, #FCFAF7);
    font-size: 1.25rem;
    line-height: 1.375rem;
    text-transform: uppercase;
    color: var(--white, #FCFAF7);
    padding: 0.5rem 1.25rem;
    background: none;
    cursor: pointer;

    &.dark {
      border: 3px solid var(--white, #1B1B1E);
      color: #1B1B1E;
    }
  }
</style>