<template>

  <div class="container">

    <h1>
      {{ $t('aboutUsInTheMedia') }}
    </h1>

    <div class="wrapper desktop" >

      <div class="card">
        <img src="@/assets/media-logo/pink-news.png">
        <p class="date">Mar 16, 2023</p>
        <p @click="openSocial('https://www.thepinknews.com/2023/09/29/russia-trans-conversion-therapy-anti-lgbtq-putin/')" class="description">
          Russia: Trans woman forced into conversion therapy camp for nine months ‘had to castrate a pig’
        </p>
      </div>

      <div class="card">
        <img src="@/assets/media-logo/BBC.png">
        <p class="date">Feb 13, 2023</p>
        <p @click="openSocial('https://www.youtube.com/watch?v=BQkQArHdC7g&ab_channel=BBCNews-%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B0%D1%8F%D1%81%D0%BB%D1%83%D0%B6%D0%B1%D0%B0')" class="description">
          Как превратить трансфобный твит в донат 12 000 000 рублей
        </p>
      </div>

      <div class="card">
        <img src="@/assets/media-logo/pink-news.png">
        <p class="date">{{ $t('apr282023') }}</p>
        <p @click="openSocial('https://www.thepinknews.com/2023/04/28/gela-gogishvili-haoyang-xu-russia-anti-lgbtq-queer-vladimir-putin/')" class="description">
          {{ $t('youtuberArrestedForLgbtqPropagandaInRussiaWarnsPut') }}
        </p>
      </div>

    </div>
    <div class="wrapper mobile" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
      <div v-show="slide === 1" class="card">
        <img src="@/assets/media-logo/edgemedia.png">
        <p class="date">{{ $t('mar162023') }}</p>
        <p class="description">
          {{ $t('activistAndAlliesOfferLgbtqUkrainianRefuqeesALifet') }}
        </p>
      </div>

      <div v-show="slide === 2" class="card">
        <img src="@/assets/media-logo/moscow-times.png">
        <p class="date">{{ $t('feb132023') }}</p>
        <p class="description">
          {{ $t('ukraineWarFuelsFearAndAnxietyForRussiasTransCommun') }}
        </p>
      </div>

      <div v-show="slide === 3" class="card">
        <img src="@/assets/media-logo/pink-news.png">
        <p class="date">{{ $t('apr282023') }}</p>
        <p class="description">
          {{ $t('youtuberArrestedForLgbtqPropagandaInRussiaWarnsPut') }}
        </p>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="8" viewBox="0 0 40 8" fill="none">
        <circle cx="4" cy="4" r="4" :fill="isActive(1)"/>
        <circle cx="20" cy="4" r="4" :fill="isActive(2)"/>
        <circle cx="36" cy="4" r="4" :fill="isActive(3)"/>
      </svg>
    </div>

  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default {
  name: "aboutUsInMedia",
  data() {
    return {
      slide: 1
    }
  },
  methods: {
    openSocial(link){
      window.open(link, '_blank')
    },
    isActive(number) {
      if (number === this.slide) {
        return "#EC408D"
      } else return "#FDB643"
    },
    onTouchStart(event) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    onTouchMove(event) {
      this.endX = event.touches[0].clientX;
      this.endY = event.touches[0].clientY;
    },
    onTouchEnd() {
      let diffX = this.endX - this.startX;
      let diffY = this.endY - this.startY;

      if (Math.abs(diffX) > Math.abs(diffY)) {
        if (diffX > 0) {
          if (this.slide === 1) {
            this.slide = 3;
          } else {
            this.slide = this.slide - 1;
          }
        } else {
          if (this.slide === 3) {
            this.slide = 1
          } else  {
            this.slide = this.slide + 1;
          }
          console.log("Свайп влево");
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">

.container {
  margin-top: 5.625rem;

  h1 {
    margin-left: 7.5rem;
    color: var(--yellow, #FDB643);
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    text-transform: uppercase;
    text-align: start;
  }

  .wrapper {
    display: flex;
    gap: 2.5rem;
    margin-top: 1.875rem;

    &.mobile {
      display: none;
      flex-direction: column;

      .card {
        margin-left: 0.833rem;
        margin-right: 0.833rem;
      }

      svg {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .card {
      flex: 1;
      border: 0.25rem solid var(--yellow, #FDB643);
      padding: 1.5rem;
      display: flex;
      gap: 0.75rem;
      flex-direction: column;

      img {
        align-self: flex-start;
      }

      .date {
        color: var(--black, #1B1B1E);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
        align-self: flex-start;
      }

      .description {
        color: var(--black, #1B1B1E);
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.875rem;
        text-decoration-line: underline;
        text-align: start;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 978px) {
  .container {
    .desktop {
      display: none;
    }
    .mobile {
      display: flex !important;
    }

    h1 {
      margin-left: 2rem;
    }
  }
}

</style>