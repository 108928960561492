<template>
  <div class="container">
    <div class="text-container">
      <h1>
        {{ $t('hiWeAreQueerSvit') }}
      </h1>
      <div @click="pushRoute">
        <outline-button :text="'support us'" class="button"/>
      </div>


    </div>

    <div class="image-container">
      <div class="box-animation">
        <Transition appear mode="in-out" name="fade">
          <img :key="currentImage" :src="images[currentImage]" alt="" class="image"/>
        </Transition>
        <img alt="" class="asterisk" src="@/assets/asterisk.png">
        <img alt="" class="left" src="@/assets/image-animation-left.png">
        <img alt="" class="right" src="@/assets/image-animation-right.png">
        <img alt="" class="branch" src="@/assets/animation-branch.png">
      </div>

    </div>

  </div>
</template>

<script>
import {defineComponent} from 'vue'
import OutlineButton from "@/components/buttons/outlineButton.vue";

export default defineComponent({
  name: "weAreQueerSvit",
  components: {OutlineButton},
  data() {
    return {
      images: [
        require('@/assets/animatioin-image/animation-image-1.jpg'),
        require('@/assets/animatioin-image/animation-image-2.jpg'),
        require('@/assets/animatioin-image/animation-image-3.jpg'),
        require('@/assets/animatioin-image/animation-image-4.jpg'),
        require('@/assets/animatioin-image/animation-image-5.jpg'),
        require('@/assets/animatioin-image/animation-image-6.jpg'),
        require('@/assets/animatioin-image/animation-image-7.jpg'),
        require('@/assets/animatioin-image/animation-image-8.jpg'),
        require('@/assets/animatioin-image/animation-image-9.jpg'),

      ],
      currentImage: 0,
    };
  },
  mounted() {
    this.startSlideshow();
  },
  methods: {
    pushRoute() {
      this.$router.push("donate")
    },
    startSlideshow() {
      setInterval(() => {
        this.currentImage = (this.currentImage + 1) % this.images.length;
      }, 6000);
    },
  },
})
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 2.5s ease;
}
.fade-enter, .fade-leave-to {
  transition: opacity 2.5s ease;
  opacity: 0;
  transition-delay: 2s;
}

.container {
  display: flex;
  margin-left: 4.375rem;
  margin-right: 4.375rem;
  margin-top: 5.625rem;
  padding-bottom: 5.125rem;

  .text-container {
    flex: 1 0 50%;


    h1 {
      color: var(--white, #FCFAF7);
      font-size: 5.625rem;
      font-style: normal;
      font-weight: 600;
      line-height: 6rem;
      letter-spacing: -0.16875rem;
      text-transform: uppercase;
      margin: 0;
      text-align: start;

    }

    .button {
      position: relative;
      margin-top: 2.5625rem;
      font-size: 2.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3.25rem;
      letter-spacing: -0.0825rem;
      text-transform: uppercase;
      cursor: pointer;
    }

    .button:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 4.667rem;
      width: 4.667rem;
      background-image: url("@/assets/home-page/decorator1.png");
      background-repeat: no-repeat;
      transform: translate(-50%, -30%);
      z-index: 1;
    }

    @media (max-width: 978px) {
      .button {
        width: 100%;
      }
    }
  }

  .image-container {
    flex: 1 0 50%;
    position: relative;
    display: flex;

    .box-animation {
      position: relative;
      width: 425px;
      margin-left: auto;

      img {
        position: absolute;
        top: 0;
        right: 0;
        //transform: translate(-50%, 50%);

        &.image {
          z-index: 8;
          width: 425px;
          height: 500px;
        }

        &.asterisk {
          left: -10%;
          top: 90%;
          z-index: 9;
          width: 90px;
          height: 90px; // Установите желаемую высоту картинки
          animation: spin-asterisk 6s linear infinite; // 2 секунды (1 секунда вправо и 1 секунда влево), и анимация бесконечная
        }

        @keyframes spin-asterisk {
          10%, 90% {
            transform: rotate(0deg);
            //animation-timing-function: ease-in;

          }
          40%, 60% {
            transform: rotate(40deg);
            //animation-timing-function: ease-in;
          }
          90% {
            transform: rotate(0deg);
            animation-timing-function: ease-in;
          }
        }

        &.left {
          left: -50%;
          top: 55%;
          z-index: 5;
          //width: 90px;
          //height: 90px; // Установите желаемую высоту картинки
          animation: spin-left 6s linear infinite;
        }

        @keyframes spin-left {
          10%, 90% {
            transform: rotate(0deg);
            //animation-timing-function: ease-in;
          }
          40%, 60% {
            transform: rotate(10deg);
            //animation-timing-function: ease-in;
          }
          90% {
            transform: rotate(0deg);
            //animation-timing-function: ease-in;
          }
        }

        &.right {
          right: -22%;
          top: 55%;
          z-index: 5;
          //width: 90px;
          //height: 90px; // Установите желаемую высоту картинки
          animation: spin-left 6s linear infinite;
        }

        &.branch {
          left: -28%;
          top: -8%;
          z-index: 5;
          //width: 90px;
          //height: 90px; // Установите желаемую высоту картинки
          animation: spin-branch 6s linear infinite;
        }

        @keyframes spin-branch {
          10%, 90% {
            transform: rotate(0deg);
            //animation-timing-function: ease-in;
          }
          40%, 60% {
            transform: rotate(-10deg);
            //animation-timing-function: ease-in;
          }
          90% {
            transform: rotate(0deg);
            //animation-timing-function: ease-in;
          }
        }
      }
    }


    @media (max-width: 1440px) {
      /* CSS styles for viewport width less than 1440px */
      img {
        scale: 0.8;
        //width: 60%;
        height: auto;
      }
    }
  }

  @media (max-width: 978px) {
    .image-container {
      display: none;
    }
  }
}

@media (max-width: 1440px) {
  /* CSS styles for viewport width less than 1440px */
  .box-animation {
    scale: 0.8;
  }
  .container .image-container .box-animation img.asterisk {
    left: 0;
  }
  .container .image-container .box-animation img.branch {
    left: -15%;
  }
}

</style>