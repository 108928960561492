<template>
  <div class="container">
    <h1 class="first-title">
      {{ $t('whoWeAre') }}
    </h1>
    <div class="row">
      <p>
        {{ $t('weAreABlackQueerrunIndependentTeamOfVolunteersFrom') }}
      </p>
      <p>
        {{ $t('likeAnyCatastropheTheWarAffectsTheMostVulnerablePe') }}
      </p>

    </div>

    <h1 class="second-title">
      {{ $t('whatWeDo') }}
    </h1>

    <div class="row">
      <div class="card">
        <div class="wrapper">
          <h3>{{ $t('weHelpQueerAndBamePeopleAffectedByTheWarInUkraine') }}</h3>
          <p>
            {{ $t('providingDirectAidToThoseInNeedForThoseLocatedInUk') }}
          </p>
        </div>
        <div @click="pushRoute('about')">
          <outline-button-arrow class="button" :text="'see more'"/>
        </div>

      </div>

      <div class="card">
        <div class="wrapper-pink">
          <h3>
            {{ $t('forLgbtqAndBamePeopleWhoAreAtRiskOfPersecutionInRu') }}
          </h3>
          <p>
            {{ $t('weProvideGuidanceForRelocationAndTheAsylumProcessW') }}
          </p>
        </div>
        <a style="text-decoration: none" target="_blank" href="https://t.me/Queer_Svit_bot">
          <button-arrow-pink class="button" :text="'get help'"/>
        </a>

      </div>

    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'
import OutlineButton from "@/components/buttons/outlineButton.vue";
import OutlineButtonArrow from "@/components/buttons/outlineButtonArrowOrange.vue";
import ButtonArrowPink from "@/components/buttons/buttonArrowPink.vue";

export default {
  name: "whoWeAre",
  components: {ButtonArrowPink, OutlineButtonArrow, OutlineButton},
  methods: {
    pushRoute(link) {
      if (this.$route.path !== link) {
        this.$router.push(link)
      }
    },
  }
}
</script>

<style scoped lang="scss">

p, h1, h3 {
  margin: 0;
}



.container {
  margin-top: 0.9375rem;
  border: 0.25rem solid #FDB643;
  padding: 3.75rem 4.375rem;
  border-radius: 0.875rem 0.875rem 0 0 ;
  text-align: start;

  .first-title {
    color: var(--yellow, #FDB643);
    /* title */
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    text-transform: uppercase;
    text-align: start;
    margin-bottom: 1.875rem;
  }

  .second-title {
    @extend .first-title;
    color: var(--pink, #EC408D);
    margin-top: 5.625rem;
  }

  .row {
    display: flex;
    gap: 2.5rem;


    p {
      color: var(--black, #1B1B1E);

      /* text */
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.875rem;
      text-align: start;
      flex: 1 0 50%;
    }

    .card {
      flex: 1 1 50%;
      .wrapper {
        border: 0.25rem solid var(--yellow, #FDB643);
        padding: 1.5rem;
        margin-bottom: 1.875rem;

        &-pink {
            @extend .wrapper;
            border: 0.25rem solid var(--pink, #EC408D);
        }

        h3 {
          color: var(--black, #1B1B1E);
          /* sub title */
          font-size: 1.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.25rem;
          text-transform: uppercase;
          margin-bottom: 0.75rem;
        }
      }
      .button {
        margin-left: auto;
      }

      @media (max-width: 978px) {
        .button {
          width: 100%;
        }
      }
    }


  }

  @media (max-width: 978px) {
    .row {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 978px) {
  .container {
    border: none;
    padding: 1.875rem 2.187rem;
  }
}

</style>