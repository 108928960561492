<template>

  <div class="container">
<!--    <div class="column">-->
<!--      <h1>-->
<!--        {{ $t('support') }} <br>{{ $t('queerSvit2') }}-->
<!--      </h1>-->
<!--      <div class="container-btn">-->
<!--        <div class="row">-->
<!--          <div @click="changePeriod('monthly')">-->
<!--            <donate-button :class="{active: isPeriod('monthly')}" :text="'monthly'"/>-->
<!--          </div>-->
<!--          <div @click="changePeriod('once')">-->
<!--            <donate-button :class="{active: isPeriod('once')}" :text="'once'"/>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div class="row">-->
<!--          <div @click="changeAmount(5)">-->
<!--            <donate-button :class="{active: isAmount(5)}" :text="'5$'"/>-->
<!--          </div>-->

<!--          <div @click="changeAmount(10)">-->
<!--            <donate-button :class="{active: isAmount(10)}" :text="'10$'"/>-->
<!--          </div>-->

<!--          <div @click="changeAmount(15)">-->
<!--            <donate-button :class="{active: isAmount(15)}" :text="'15$'"/>-->
<!--          </div>-->

<!--          <div @click="changeAmount(20)">-->
<!--            <donate-button :class="{active: isAmount(20)}" :text="'20$'"/>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div class="row">-->
<!--          <div @click="changeAmount(50)">-->
<!--            <donate-button :class="{active: isAmount(50)}" :text="'50$'"/>-->
<!--          </div>-->

<!--          <div @click="changeAmount(100)">-->
<!--            <donate-button :class="{active: isAmount(100)}" :text="'100$'"/>-->
<!--          </div>-->

<!--          <donate-input class="other-amount" type="number" :placeholder="'other amount'"/>-->
<!--        </div>-->
<!--        <div class="row payment-data">-->
<!--          <donate-input v-model="donation.email" :placeholder="'e-mail'"/>-->
<!--          <donate-input v-model="donation.name" :placeholder="'name'"/>-->
<!--          <donate-input :placeholder="'phone'"/>-->
<!--        </div>-->


<!--        <div class="row">-->
<!--          <donate-button class="active" :text="'donate'" style="width: 100%"/>-->
<!--        </div>-->

<!--        <div class="row">-->
<!--          <span>-->
<!--            {{ $t('byClickingTheDonateButtonYouConsentToTheProcessing') }} <u>{{ $t('personalData') }}</u>-->
<!--          </span>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->

    <div class="column other-ways">
      <h1>
        support queer svit
<!--        {{ $t('otherWays') }} <br>{{ $t('toHelp') }}-->
      </h1>
      <div class="container-btn">

        <div class="row">
          <donate-button @click="openLink" :text="'revolute'"/>
          <donate-button @click="copyToClipboard('Amtesfaye@protonmail.com')" :text="'paypal'"/>
<!--          <donate-button @click="copyToClipboard('2200 1502 3930 3099')" :text="'альфа банк'"/>-->
          <div v-if="copied" class="copy-notification"> Copied: {{ copiedText  }}</div>

        </div>

        <div class="row">

            <donate-qr-code
                style="margin-left: 0"
                :title="'bitcoin'"
                :image="qrBitcoin"
                :wallet="'bc1q6deqyne42lmnfcceah4jz9pkysu5cg3qtsha8m'"
                @click="copyToClipboard('bc1q6deqyne42lmnfcceah4jz9pkysu5cg3qtsha8m')"
            />

            <donate-qr-code
                :title="'ETH'"
                :image="qrETH"
                :wallet="'0x059112863f5CE8539cB2AF39379b0ce3A6C616Cf'"
                @click="copyToClipboard('0x059112863f5CE8539cB2AF39379b0ce3A6C616Cf')"
            />

            <donate-qr-code
                :title="'XMR'"
                :image="qrXMR"
                :wallet="'434jx21x9CCceqL917C4SNKQcPXic22mqQyPjNUgmcVNQP3sxsJWb8dGLdHsVo4PV1jD4BBHjLNxq7HiLkR6Sojz46UrfDr'"
                @click="copyToClipboard('434jx21x9CCceqL917C4SNKQcPXic22mqQyPjNUgmcVNQP3sxsJWb8dGLdHsVo4PV1jD4BBHjLNxq7HiLkR6Sojz46UrfDr')"
            />


        </div>

      </div>
    </div>
  </div>

</template>

<script>
import DonateButton from "@/components/buttons/donateButton.vue";
import DonateInput from "@/components/inputs/donateInput.vue";
import DonateQrCode from "@/components/cards/donateQrCode.vue";
import qrBitcoin from "@/assets/qr/BitcoinQS.png"
import qrETH from "@/assets/qr/EthereumQS.png"
import qrXMR from "@/assets/qr/moneroQS.png"
import Clipboard from "clipboard";
export default {
  name: "donate",
  components: {DonateQrCode, DonateInput, DonateButton},
  data() {
    return {
      qrBitcoin: qrBitcoin,
      qrETH: qrETH,
      qrXMR: qrXMR,
      donation: {
        period: "once",
        amount: 5,
        email: "",
        name: "",
        phone: ""
      },
      textToCopy: "Amtesfaye@protonmail.com", // Замените на свой текст
      clipboard: null,
      copied: false,
      copiedText: "",
    };
  },
  mounted() {
    this.clipboard = new Clipboard(this.$el, {
      text: () => this.copiedText,
    });
    this.clipboard.on("success", this.handleSuccess);
  },
  methods: {
    isPeriod(period) {
      return period === this.donation.period;
    },
    isAmount(amount) {
      return amount === this.donation.amount;
    },
    changePeriod(period) {
      this.donation.period = period;
    },

    changeAmount(amount) {
      this.donation.amount = amount;
    },
    openLink() {
      const url = "https://revolut.me/annamaynv4"; // Замените на вашу ссылку
      window.open(url, "_blank");
    },
    copyToClipboard(text) {
      this.copiedText = text;
      if (this.clipboard) {
        this.clipboard.onClick();
      }
    },
    handleSuccess() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
  },
  beforeDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  },
}
</script>

<style scoped lang="scss">
.copy-notification {
  background-color: rgb(237 70 137 / 75%);
  color: white;
  padding: 10px;
  position: fixed;
  top: 2%;
  right: 50%;
  transform: translate(50%, 0);
  z-index: 999;
  border-radius: 5px;
  max-width: 250px;
  word-break: break-all;
}


.container {
  text-align: start;
  margin-top: 7.5rem;
  display: flex;
  gap: 8.75rem;
  padding: 3.75rem 4.375rem;
  background: linear-gradient(to right, #EC408D, #FDB643);
  border-radius: 0.875rem;
  flex-wrap: wrap;
}

.column {
  flex: 1;

  @media (max-width: 978px) {
    //&.other-ways {
    //  display: none;
    //}
  }

  h1 {
    color: var(--white, #FCFAF7);
    /* title */
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    text-transform: uppercase;
  }

  .container-btn {
    margin-top: 1.875rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;


    .row {
      display: flex;
      gap: 1.25rem;
      flex-wrap: wrap;

      * {
        box-sizing: border-box;
      }

      @media (max-width: 978px) {
        .other-amount {
          flex: 0 0 100%;
        }
      }

      &.payment-data {


        input {
          flex: 0 0 45%;
          box-sizing: border-box;
        }

        @media (max-width: 978px) {
          input {
            flex: 0 0 100%;
          }
        }
      }


      span {
        color: var(--white, #FCFAF7);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;

        u {
          cursor: pointer;
        }
      }
    }
  }
}

</style>