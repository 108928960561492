<template>
  <div class="container">
    <h1>
      {{ $t('whyLgbtqAndBamePeople') }}
    </h1>

    <div class="wrapper">
      <div class="column">
        <h2>
          {{ $t('blackAsianMinorityEthnic') }}
        </h2>
        <img src="@/assets/family.png">
        <p>
          <span>
            —
          </span>
          <span>
            {{ $t('ukrainianTransgenderWomenThatHaventHadTheirDocumen') }}
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
           {{ $t('russianTransgenderWomenWhoHaventYetChangedTheirLeg') }}
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
           {{ $t('russianLgbtqCouplesWithKidsAreInDangerOfTheirKidsB') }}
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
            {{ $t('inRussiaAndBelarusTheRepressionsAgainstTheQueerAct') }}
          </span>
        </p>

      </div>
      <div class="column-right">
        <h2>
          {{ $t('catastrophesSuchAsWarMostImpactThoseWhoAreAlreadyV') }}
        </h2>

        <p>
          <span>
            —
          </span>
          <span>
            {{ $t('manyBamePeopleWhoLivedInUkraineBeforeTheWarAreNowB') }}
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
            {{ $t('someOfTheBamePeopleAreAlsoLgbtqAndThusFaceDualDisc') }}
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
            {{ $t('becauseOfTheirVulnerablePositionBamePeopleAreAlsoT') }}
          </span>
        </p>

        <hr>

        <p>
          <span>
            —
          </span>
          <span>
            {{ $t('samesexRefugeeCouplesAreOftenUnableToReuniteBecaus') }}
          </span>
        </p>

        <img src="@/assets/lgbt.png">

      </div>

    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "whyLGBTq"
})
</script>

<style scoped lang="scss">
.container {
  margin-top: 5.625rem;
  //padding-left: 3.125rem;
  //padding-right: 3.125rem;

  h1 {
    color: var(--pink, #EC408D);
    padding-left: 4.375rem;

    /* title */
    font-size: 3.125rem;
    //font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    text-transform: uppercase;
    text-align: start;
  }
  @media (max-width: 1440px) {
    /* CSS styles for viewport width less than 1440px */
    h1 {
      padding-left: 0;
    }

  }

  .wrapper {
    display: flex;
    gap: 2.5rem;
    margin-top: 1.875rem;
    flex-wrap: wrap;

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;



      h2 {
        color: var(--black, #1B1B1E);

        /* sub title */
        font-size: 1.75rem;
        //font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 2.25rem;
        text-transform: uppercase;
        text-align: start;
        padding-left: 4.375rem;
      }

      img {
        margin-top: 2.75rem;
        max-width: 100%;
      }

      p {
        display: flex;
        gap: 1.2rem;
        padding-left: 4.375rem;
        margin-top: 1.875rem;

        color: var(--black, #1B1B1E);

        /* text */
        font-size: 1.375rem;
        //font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 1.875rem;
        text-align: start;
      }

      hr {
        display: none;
      }

      @media (max-width: 1440px) {
        /* CSS styles for viewport width less than 1440px */
        p {
          padding-left: 0;

        }

        h2 {
          padding-left: 0;
        }

      }

      @media (max-width: 978px) {
        p {
          margin-bottom: 1.875rem;
        }

        hr {
          display: block;
          background: #FDB643;
          border: none;
          height: 0.25rem;
          width: 80%;
        }

        img {
          order: 1;
        }
      }

      &-right {
        @extend .column;

        p {
          padding-left: 0;
          padding-right: 4.375rem;
        }

        h2 {
          padding-left: 0;
          padding-right: 4.375rem;
        }

        @media (max-width: 1440px) {
          /* CSS styles for viewport width less than 1440px */
          p {
            padding: 0;
          }
          h2 {
            padding: 0;
          }

        }

      }
    }

    @media (max-width: 978px) {
      .column {
        flex: 1 0 100%
      }
    }
  }
}

@media (max-width: 978px) {
  .container {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
</style>