<template>

  <button class="outline">
    <span>{{ text }}</span>
    <img src="@/assets/icons/orange-arrow.png">
  </button>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "outlineButtonArrowOrange",
  props: ['text']
})
</script>

<style scoped lang="scss">
.outline {
  display: flex;
  gap: 0.75rem;
  border: 0.25rem solid var(--yellow, #FDB643);
  color: var(--yellow, #FDB643);
  /* button */
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.25rem;
  letter-spacing: -0.06375rem;
  text-transform: uppercase;
  padding: 0.5rem 1.25rem;
  background: none;
  cursor: pointer;

  span {
    margin-left: auto;
  }

  img {
    width: 1.625rem;
    height: 1.625rem;
    margin: auto auto auto 0;
  }
}
</style>