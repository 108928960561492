<template>
  <div class="container">
    {{ $t('queerSvitIsStillASmallerInitiativeWeCanContinueOur') }}<br><br>

    {{ $t('belowYouCanFindOutMoreAboutOurWork') }}
  </div>

</template>

<script>
export default {
  name: "mobile-description"
}
</script>


<style scoped lang="scss">

.container {
  display: none;
  margin-top: 2.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: left;
  color: var(--black, #1B1B1E);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

@media (max-width: 978px) {
  .container {
    display: block;
  }
}
</style>