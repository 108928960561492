<template>
  <div class="container">
    <h1>
      {{ $t('ourTeam') }}
    </h1>
    <p>
      {{ $t('unfortunatelyNotAllMembersOfourTeamAreAbleTotalkOp') }}
    </p>

    <div class="wrapper">
      <div class="card">
        <div class="vertical-text">
          <p class="title">
            Anna-Maria
          </p>
          <p class="description">
            {{ $t('cofounderCeo') }}
          </p>

        </div>
        <img src="@/assets/about-page/anna-maria.png">
      </div>

<!--      <div class="card">-->
<!--        <div class="vertical-text pink">-->
<!--          <p class="title">-->
<!--            Ollie-->
<!--          </p>-->
<!--          <p class="description">-->
<!--            Head of marketing-->
<!--          </p>-->

<!--        </div>-->
<!--        <img src="@/assets/about-page/ollie.png">-->
<!--      </div>-->

      <div class="card">
        <div class="vertical-text pink">
          <p class="title">
            {{ $t('suzy') }}
          </p>
          <p class="description">
            {{ $t('cofounderGraphicDesigner') }}
          </p>

        </div>
        <img src="@/assets/about-page/suzy.png">
      </div>

<!--      <div class="card">-->
<!--        <div class="vertical-text">-->
<!--          <p class="title">-->
<!--            {{ $t('alexandra') }}-->
<!--          </p>-->
<!--          <p class="description">-->
<!--            {{ $t('caseManager') }}-->
<!--          </p>-->

<!--        </div>-->
<!--        <img src="@/assets/about-page/alexandra.png">-->
<!--      </div>-->
    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "our-team"
})
</script>


<style scoped lang="scss">
.container {
  margin-top: 10rem;
  margin-left: 4.375rem;
  margin-right: 4.375rem;
  text-align: start;

  h1 {
    color: var(--pink, #EC408D);

    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 120% */
    text-transform: uppercase;
  }

  p {
    margin-top: 2.5rem;
    color: var(--black, #1B1B1E);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 136.364% */
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5rem;
    gap: 3.333rem;

    .card {
      flex: 1 0 calc(33.3% - 3.333rem);
      display: flex;
      margin: auto;
      overflow: hidden;

      .vertical-text {
        padding-bottom: 5.5rem;
        background: var(--yellow, #FDB643);
        writing-mode: vertical-rl;
        transform: rotate(180deg);

        &.pink {
          background: var(--pink, #EC408D);
        }


        .title {
          color: var(--white, #FCFAF7);

          /* button */
          font-size: 34px;
          font-style: normal;
          font-weight: 700;
          line-height: 52px; /* 152.941% */
          letter-spacing: -1.02px;
          text-transform: uppercase;
        }

        .description {
          color: var(--white, #FCFAF7);

          /* text */
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 136.364% */
        }
      }

      img {
        height: 28.333rem;
        width: 100%;
        object-fit: cover;
        object-position: 80% 0%;
      }
    }

  }

}

@media (max-width: 1440px) {
  .container{
    margin: 0;
  }
}
@media (max-width: 1200px) {
  .container{
    .wrapper {

      .card {
        flex: 1 0 calc(50% - 3.333rem);
      }
    }
  }
}

@media (max-width: 978px) {
  .container {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    .wrapper {
      flex-direction: column;
      margin: 2.5rem 20%;
      .card{
        margin: initial;
        .vertical-text{
          padding-bottom: 1.5rem;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    .wrapper {
      margin: 2.5rem 10%;
    }
  }
}

</style>