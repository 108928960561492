<template>

  <div class="container">
    <div class="title-line">
      <h1>
        {{ $t('ourResultsFor2022') }}
      </h1>
      <div class="button" @click="downloadReport">
        <outline-button-arrow-orange class="button" :text="'read full report'"/>
      </div>

    </div>

    <div class="information">
      <div class="item">
        <h1>
          1023
        </h1>
        <span>
          {{ $t('consultationsProvided') }}
        </span>
      </div>

      <div class="line"/>

      <div class="item">
        <h1>
          146
        </h1>
        <span>
          {{ $t('personsHelpedWithRelocationToASaferLocation') }}
        </span>
      </div>

      <div class="line"/>

      <div class="item">
        <h1>
          154
        </h1>
        <span>
          {{ $t('lgbtqAndBamePeopleFromUkraineWeveHelpedFinancially') }}
        </span>
      </div>

    </div>

    <div @click="downloadReport">
      <outline-button-arrow-orange class="button-mobile" :text="'read full report'"/>
    </div>



  </div>

</template>

<script>
import reportPdf from '@/assets/report.pdf';
import OutlineButtonArrowOrange from "@/components/buttons/outlineButtonArrowOrange.vue";

export default {
  name: "ourResults",
  components: {OutlineButtonArrowOrange},
  data(){
    return {
      reportUrl: reportPdf
    }
  },
  methods: {
    downloadReport(){
      window.open(this.reportUrl, '_blank');
    }
  }
}
</script>

<style scoped lang="scss">

.container {
  margin-top: 8rem;
  margin-left: 4.375rem;
  margin-right: 4.375rem;

  .title-line {
    display: flex;

    h1 {
      margin-top: auto;
      margin-bottom: auto;

      color: var(--yellow, #FDB643);

      /* title */
      font-size: 3.125rem;
      //font-family: Inter;
      font-style: normal;
      font-weight: 700;
      line-height: 3.75rem;
      text-transform: uppercase;
      text-align: start;
    }

    .button {
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto;
    }

    @media (max-width: 978px) {
      .button {
        display: none;
      }
    }
  }

  .information {
    display: flex;
    position: relative;
    margin-top: 1.875rem;
    gap: 4rem;
    height: 100%;

    .line {
      background: #FDB643;
      width: 0.25rem;
    }

    .item {
      margin: 0 auto auto;
      flex: 1;

      h1 {
        color: var(--pink, #EC408D);
        text-align: center;
        font-size: 5.75rem;
        //font-family: Inter;
        font-style: normal;
        font-weight: 700;
        line-height: 5.75rem;
        letter-spacing: -0.2875rem;
        text-transform: uppercase;
      }

      span {
        color: var(--black, #1B1B1E);
        text-align: center;
        margin-top: 1rem;

        /* sub title */
        font-size: 1.75rem;
        //font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 2.25rem;
        text-transform: uppercase;
      }
    }
  }

  .button-mobile {
    display: none;
  }

  @media (max-width: 978px) {

    .button-mobile {
      display: block;
      width: 100%;
      margin-top: 2rem;
    }

    .information {
      flex-wrap: wrap;

      .item {
        flex: 1 0 100%;
      }

      .line {
        height: 0.25rem;
        width: 80%;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}

</style>