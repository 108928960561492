import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from "@/views/AboutView.vue";
import DonateView from "@/views/DonateView.vue";
import ThankYou from "@/views/ThankYou.vue";
import ReportsView from "@/views/ReportsView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/donate',
    name: 'donate',
    component: DonateView
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: ThankYou
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
