<template>
  <div class="container">
    <h1>{{ $t('helpUsHelpOthers') }}</h1>
    <div @click="pushRoute('/donate')" class="button">
      <outline-button class="button" :text="`support our work`"/>
    </div>

  </div>
</template>

<script>
import {defineComponent} from 'vue'
import OutlineButton from "@/components/buttons/outlineButton.vue";

export default {
  name: "help-us",
  components: {OutlineButton},
  methods: {
    pushRoute(link) {
      if (this.$route.path !== link) {
        this.$router.push(link)
      }
    },
  }
}
</script>

<style scoped lang="scss">

.container {
  background: linear-gradient(to right, #EC408D, #FDB643);
  border-radius: 0.875rem;
  display: flex;
  padding: 2.5rem 4.375rem;
  margin-top: 5rem;

  h1 {
    color: var(--white, #FCFAF7);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    text-transform: uppercase;
    margin-top: auto;
    margin-bottom: auto;
  }
  .button {
    margin-left: auto;
    margin-right: 0;

    color: var(--white, #FCFAF7);
    /* button */
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 152.941% */
    letter-spacing: -1.02px;
    text-transform: uppercase;
  }
}

@media (max-width: 978px) {
  .container {
    text-align: start;
    flex-direction: column;

    .button {
      margin: 1.667rem auto auto;
      width: 100%;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
    }
  }
}

</style>