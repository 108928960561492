<template>
  <div class="container">
    <h1>
      {{ $t('feedback') }}
    </h1>

    <div class="wrapper desktop"
    >
      <div class="card">
        <h2>
          {{ $t('m') }}
        </h2>

        <p>
          {{ $t('queerSvitSwiftlyPaidForMyMedicalCommissionAndNowIF') }}
        </p>
      </div>

      <div class="card">
        <h2>
          {{ $t('n') }}
        </h2>

        <p>
          {{ $t('aFriendOfMineWhodPreviouslyBeenHelpedByQueerSvitTo') }}
        </p>
      </div>

      <div class="card">
        <h2>
          {{ $t('a') }}
        </h2>

        <p>
          {{ $t('aDayBeforeTheFlightTheQuestionOfWhereToStayInParis') }}
        </p>
      </div>
    </div>

    <div @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd"
         class="wrapper mobile">

      <div v-show="slider === 1" class="card">
        <h2>
          {{ $t('m') }}
        </h2>

        <p>
          {{ $t('queerSvitSwiftlyPaidForMyMedicalCommissionAndNowIF') }}
        </p>
      </div>

      <div v-show="slider === 2" class="card">
        <h2>
          {{ $t('n') }}
        </h2>

        <p>
          {{ $t('aFriendOfMineWhodPreviouslyBeenHelpedByQueerSvitTo') }}
        </p>
      </div>

      <div v-show="slider === 3" class="card">
        <h2>
          {{ $t('a') }}
        </h2>

        <p>
          {{ $t('aDayBeforeTheFlightTheQuestionOfWhereToStayInParis') }}
        </p>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="8" viewBox="0 0 40 8" fill="none">
        <circle cx="4" cy="4" r="4" :fill="isActive(1)"/>
        <circle cx="20" cy="4" r="4" :fill="isActive(2)"/>
        <circle cx="36" cy="4" r="4" :fill="isActive(3)"/>
      </svg>

    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "feedback",
  data() {
    return {
      slider: 1
    }
  },
  methods: {
    isActive(number) {
      if (number === this.slider) {
        return "#EC408D"
      } else return "#FDB643"
    },
    onTouchStart(event) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    onTouchMove(event) {
      this.endX = event.touches[0].clientX;
      this.endY = event.touches[0].clientY;
    },
    onTouchEnd() {
      let diffX = this.endX - this.startX;
      let diffY = this.endY - this.startY;

      if (Math.abs(diffX) > Math.abs(diffY)) {
        if (diffX > 0) {
          if (this.slider === 1) {
            this.slider = 3;
          } else {
            this.slider = this.slider - 1;
          }
        } else {
          if (this.slider === 3) {
            this.slider = 1
          } else  {
            this.slider = this.slider + 1;
          }
          console.log("Свайп влево");
        }
      }

    }
  }
})
</script>

<style scoped lang="scss">

.container {
  margin-top: 5.625rem;

  h1 {
    padding-left: 4.375rem;
    color: var(--pink, #EC408D);

    /* title */
    font-size: 3.125rem;
    //font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: 3.75rem;
    text-transform: uppercase;
    text-align: start;
  }


  .wrapper {
    display: flex;
    gap: 2.5rem;
    margin-top: 1.875rem;

    .card {
      border: 0.25rem solid var(--yellow, #FDB643);
      margin: 0 auto auto;
      padding: 1.5rem;
      flex: 1;

      h2 {
        color: var(--pink, #EC408D);

        /* button */
        font-size: 2.125rem;
        //font-family: Inter;
        font-style: normal;
        font-weight: 700;
        line-height: 3.25rem;
        letter-spacing: -0.06375rem;
        text-transform: uppercase;
      }

      p {
        color: var(--black, #1B1B1E);

        /* text */
        font-size: 1.375rem;
        //font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 1.875rem;
      }
    }
  }

  .mobile {
    display: none;

    svg {
      margin-left: auto;
      margin-right: auto;
    }
  }


  @media (max-width: 978px) {

    .mobile {
      display: flex;
      flex-direction: column;
    }

    .desktop {
      display: none;
    }

    .wrapper {
      margin-left: 0.833rem;
      margin-right: 0.833rem;
    }
  }
}

</style>