<template>
  <div class="wrapper">
    <div class="header-gradient">
      <menu-top></menu-top>
      <we-are-queer-svit/>
    </div>
    <who-we-are/>
    <our-results/>
    <why-l-g-b-tq/>
    <feedback/>
    <donate/>
    <donate-other-ways-mobile/>
    <about-us-in-media/>
    <our-partners/>
    <subscription />
  </div>

</template>

<script>
// @ is an alias to /src

import MenuTop from "@/components/MenuTop.vue";
import WeAreQueerSvit from "@/components/mainPage/weAreQueerSvit.vue";
import WhoWeAre from "@/components/mainPage/whoWeAre.vue";
import OurResults from "@/components/mainPage/ourResults.vue";
import WhyLGBTq from "@/components/mainPage/whyLGBTq.vue";
import Feedback from "@/components/mainPage/feedback.vue";
import Donate from "@/components/mainPage/donate.vue";
import AboutUsInMedia from "@/components/mainPage/aboutUsInMedia.vue";
import OurPartners from "@/components/mainPage/ourPartners.vue";
import Subscription from "@/components/mainPage/subscription.vue";
import DonateOtherWaysMobile from "@/components/mainPage/donateOtherWaysMobile.vue";

export default {
  name: 'HomeView',
  components: {
    DonateOtherWaysMobile,
    Subscription,
    OurPartners, AboutUsInMedia, Donate, Feedback, WhyLGBTq, OurResults, WhoWeAre, WeAreQueerSvit, MenuTop},
}
</script>

<style lang="scss" scoped>

  .display {
    display: none;
  }

</style>
