<template>
  <div class="wrapper">
    <div class="header-gradient">
      <menu-top/>
      <thank-you-component/>
    </div>
    <subscription/>
  </div>

</template>

<script>
import MenuTop from "@/components/MenuTop.vue";
import ThankYouComponent from "@/components/thankYouPage/thankYouComponent.vue";
import Subscription from "@/components/mainPage/subscription.vue";

export default {
  name: "ThankYou",
  components: {Subscription, ThankYouComponent, MenuTop}
}
</script>

<style scoped lang="scss">

</style>