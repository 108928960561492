<template>

  <div class="container">
    <div class="col">
      <p>
        {{ $t('queerSvitZSRegistrationNumber') }}
      </p>

      <p class="pointer">
        media@queersvit.org
      </p>

      <p class="pointer" style="margin-top: 2.625rem">
        {{ $t('foundingDocuments') }}
      </p>

      <p class="pointer" @click="openPDF">
        {{ $t('privacyPolicy') }}
      </p>

      <!--      <p class="pointer">-->
      <!--        {{ $t('disclaimer') }}-->
      <!--      </p>-->

      <!--      <p class="pointer">-->
      <!--        Manage cookies-->
      <!--      </p>-->

    </div>

    <div class="col">
      <p class="pointer" @click="pushRoute('/about')">
        {{ $t('aboutUs') }}
      </p>

      <p class="pointer" @click="pushRoute('/donate')">
        {{ $t('donate2') }}
      </p>

      <!--      <p class="pointer">-->
      <!--        {{ $t('ourMission') }}-->
      <!--      </p>-->

      <!--      <p @click="pushRoute('/donate')" class="pointer">-->
      <!--        {{ $t('howWeHelp') }}-->
      <!--      </p>-->

      <p class="pointer" style="margin-top: auto" @click="openSocial('https://t.me/Queer_Svit_bot')">
        {{ $t('getHelp') }}
      </p>

<!--      <p class="pointer" @click="openSocial('https://t.me/Queer_Svit_bot')">-->
<!--        {{ $t('becomeAVolunteer') }}-->
<!--      </p>-->

    </div>

    <div class="col">

      <p class="pointer" @click="openSocial('https://www.instagram.com/queer_svit/')">
        {{ $t('instagram') }}
      </p>

      <p class="pointer" @click="openSocial('https://www.facebook.com/profile.php?id=100082989205807')">
        {{ $t('facebook') }}
      </p>

      <p class="pointer" @click="openSocial('https://twitter.com/QueerSvit')">
        {{ $t('twitterRu') }}
      </p>

      <p class="pointer" @click="openSocial('https://twitter.com/QueerSvitUK')">
        {{ $t('twitterEng') }}
      </p>

      <p class="pointer" @click="openSocial('https://t.me/queersvit')">
        {{ $t('telegram') }}
      </p>

    </div>

  </div>

</template>

<script>

import filePath from "@/assets/Privacy-Policy-Eng.pdf";

export default {
  name: "Footer",
  methods: {
    openSocial(link) {
      window.open(link, '_blank')
    },

    pushRoute(link) {
      if (this.$route.path !== link) {
        this.$router.push(link)
      }
    },

    openPDF() {
      if (this.$i18n.locale === 'en' || this.$i18n.locale === 'uk') {
        const filePath = require('@/assets/Privacy-Policy-Eng.pdf');
        const pdfURL = filePath.default;
        window.open(pdfURL, '_blank');
      } else {
        const filePath = require('@/assets/Privacy-Policy-RUS.pdf');
        const pdfURL = filePath.default;
        window.open(pdfURL, '_blank');
      }


    }
  }
}
</script>

<style lang="scss" scoped>

.container {
  display: flex;
  padding: 3.75rem 4.375rem;
  background: linear-gradient(to right, #EC408D, #FDB643);
  border-radius: 0.875rem 0.875rem 0 0;
  margin-top: 7.5rem;
  gap: 2.5rem;

  .col {
    flex: 1;
    gap: 0.75rem;
    text-align: start;
    display: flex;
    flex-direction: column;

    p {
      color: var(--white, #FCFAF7);
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.875rem;

      &.pointer {
        cursor: pointer;
      }
    }

  }
}

@media (max-width: 978px) {
  .container {
    flex-wrap: wrap;

    .col {
      flex: 0 0 40%;
    }
  }
}

</style>