<template>
  <div class="container">
   <h1>
     {{ $t('otherWaysToHelp') }}
   </h1>

    <div class="container-button">
      <donate-button :text="'Revolut'"/>
      <donate-button :text="'paypal'"/>
<!--      <donate-button :text="'Альфа банк'"/>-->

    </div>
  </div>

</template>

<script>
import DonateButton from "@/components/buttons/donateButton.vue";

export default {
  name: "donateOtherWaysMobile",
  components: {DonateButton}
}
</script>

<style scoped lang="scss">
.container{
  display: none;
  border: 4px solid var(--yellow, #FDB643);
  border-radius: 0.875rem;
  padding: 3.333rem 2rem;
  text-align: start;

  h1 {
    color: var(--yellow, #FDB643);
    /* Mobile/title mobile */
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px; /* 118.75% */
    text-transform: uppercase;
  }

  .container-button {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    button{
      width: 45%;
      box-sizing: border-box;
      color:var(--yellow, #FDB643);
      border: 0.25rem solid var(--yellow, #FDB643);
    }
    button:last-of-type{
      width: 55%;
    }
  }
}

@media (max-width: 978px) {
  .container {
    display: block;
  }
}

</style>